import React, { useRef } from 'react';
import './Join.css'
import emailjs from '@emailjs/browser';

const Join = () => {
    const form= useRef()
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div className="Join" id="join-us">
      <div className="left-j">
      <hr />
      <div>
      <span className="stroke-text">READY TO</span>
     <span> level up</span>
     </div>
     <div>
     <span>Your body</span>
     <span className="stroke-text"> With us?</span>
     </div>
     </div>
     <div className="right-j"></div>
     <form ref={form} className="email-container" onSubmit={sendEmail}>
     <input type="email" name="user-email" placeholder="Enter Your Email Address"></input>
     <button className="btn btn-j">Join Now</button>
     
     </form>
    </div>
  )
}

export default Join
